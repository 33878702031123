import { render, staticRenderFns } from "./3DModelViewer.vue?vue&type=template&id=72204f00&"
import script from "./3DModelViewer.vue?vue&type=script&lang=js&"
export * from "./3DModelViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports