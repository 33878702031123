<template>
  <div>
    <p>This page will be added in the next update!</p>
  </div>
</template>

<script>
export default {
  name: "3DModelViewer",
};
</script>
